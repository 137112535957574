// import { useState } from 'react';
import './header.css';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const { t } = useTranslation();

  // const [isPopupVisible, setIsPopupVisible] = useState(false);
  // const showPopup = () => {
  //   setIsPopupVisible(true);
  //   // Hide the popup after 3 seconds
  //   setTimeout(() => {
  //     setIsPopupVisible(false);
  //   }, 3000);
  // };

  return (
    <div className="greentech__header hero__padding" id="home">
      <div className="greentech__header-content">
        <h1 className="title">
          {t('header.title')} <br />
          {t('header.subtitle')}
        </h1>
        <p>
          {t('header.description')}
        </p>
      </div>
      <div className='navpad__container'></div>
      <nav className="navpad">
        <div className="div1"><a href="#services">{t('nav.services')}</a></div>
        <div className="div2"><a href="#aboutus">{t('nav.aboutUs')}</a></div>
        <div className="div3"><a href="#findus">{t('nav.findus')}</a></div>
        <div className="div4"><a href="#contacts">{t('nav.contact')}</a></div>
        <div className="div5"><a href="#contacts">{t('nav.team')}</a></div>
        {/* <div className="div5"><a href="#portfolio" onClick={showPopup} className="portfolio-button">{t('nav.portfolio')}</a></div>
        {isPopupVisible && (
          <div className="popup">
            GreenWaves Technologies Portfolio section is coming soon!
          </div>
        )} */}
        <div className="div6"><a href="https://www.linkedin.com/company/greenwaves-technologies-srls/" target="_blank" rel="noopener noreferrer">{t('nav.careers')}</a></div>
        <div className="div7"><a href="#partners">{t('nav.partners')}</a></div>
      </nav>
    </div>
  );
};

export default Header;
