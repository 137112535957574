import React from "react";
import './team.css';
import { useTranslation } from "react-i18next";
import FernandoImage from "../../assets/team/Fernando.jpeg";
import SamuelImage from "../../assets/team/Sam.jpeg";
import CristinaImage from "../../assets/team/Cristina.jpg";
import ClaudiaImage from "../../assets/team/Claudia.jpeg";

const Team = () => {
   const { t } = useTranslation();

   const teamMembers = [
      {
         id: 1,
         name: "Fernando Bressan",
         titleKey: "team.fernando.title",
         image: FernandoImage,
         bioKey: "team.fernando.bio",
         socialLinks: [
            { platform: "LinkedIn", url: "https://www.linkedin.com/in/fernando-bressan-268b4161/" },
         ],
      },
      {
         id: 2,
         name: "Cristina",
         titleKey: "team.cristina.title",
         image: CristinaImage,
         bioKey: "team.cristina.bio",
         socialLinks: [
            { platform: "LinkedIn", url: "#" },
         ],
      },
      {
         id: 3,
         name: "Claudia",
         titleKey: "team.claudia.title",
         image: ClaudiaImage,
         bioKey: "team.claudia.bio",
         socialLinks: [
            { platform: "LinkedIn", url: "#" },
         ],
      },
      {
         id: 4,
         name: "Samuel Nocita",
         titleKey: "team.samuel.title",
         image: SamuelImage,
         bioKey: "team.samuel.bio",
         socialLinks: [
            { platform: "LinkedIn", url: "https://www.linkedin.com/in/samuelnocita/" },
         ],
      },
   ];

   // Sub-component: Renders a single team member card
   const TeamMemberCard = ({ name, titleKey, image, bioKey, socialLinks }) => (
      <div className="team-member-card">
         <img src={image} alt={`${name}'s profile`} />
         <h3>{name}</h3>
         <p>{t(titleKey)}</p>
         <p>{t(bioKey)}</p>
         <div>
            {socialLinks.map((link, index) => (
               <a key={index} href={link.url} target="_blank" rel="noopener noreferrer">
                  {link.platform}
               </a>
            ))}
         </div>
      </div>
   );

   // Sub-component: Renders the team list
   const TeamList = () => (
      <div className="team-list">
         {teamMembers.map((member) => (
            <TeamMemberCard
               key={member.id}
               name={member.name}
               titleKey={member.titleKey}
               image={member.image}
               bioKey={member.bioKey}
               socialLinks={member.socialLinks}
            />
         ))}
      </div>
   );

   return (
      <section className="team-section">
         <h2>{t("team.title")}</h2>
         <TeamList />
      </section>
   );
};

export default Team;
