import React from 'react';
import './gwtcompanyrouter.css';
import { useTranslation } from 'react-i18next';

const GwtCompanyRouter = () => {
   const { t } = useTranslation(); // Initialize useTranslation hook

   return (
      <div className='gwtcompanyrouter'>
         <div className='gwtrouting_container'>
            <a rel="noreferrer" target="_blank" href="https://www.gwtech.it"><button> TECHNOLOGIES </button></a>
            <a rel="noreferrer" target="_blank" href="https://www.education.greenwavestechnologies.it"><button> EDUCATION </button></a>
         </div>
      </div>
   );
}

export default GwtCompanyRouter;
